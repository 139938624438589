// Modules
import './index.scss';
import lozad from 'lozad';
import Swiper from 'swiper/bundle';
import Toastify from 'toastify-js';
import { Fancybox } from '@fancyapps/ui';
import { Droppable } from '@shopify/draggable';
import Inputmask from 'inputmask/dist/inputmask.min.js';

function isMobile() {
    const devicesRegExp =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i;
    return devicesRegExp.test(navigator.userAgent);
}

if (!isMobile()) {
    const droppable = new Droppable(document.querySelectorAll('.drop-container'), {
        draggable: '.item',
        dropzone: '.dropzone',
    });

    let droppableOrigin;
    droppable.on('drag:start', (evt) => {
        droppableOrigin = evt.data.originalSource.dataset.dropzone;
    });

    droppable.on('droppable:start', (evt) => {
        if (evt.dropzone.classList.contains('construct-your-luxea__attachments-template')) {
            evt.dropzone.classList.add('free');
            // Удалить класс free для декстопа, когда берем насадку
        }
    });

    droppable.on('droppable:stop', (evt) => {
        if (evt.dropzone.classList.contains('construct-your-luxea__attachments-template')) {
            evt.dropzone.classList.remove('free');
        }
        // Добавить класс free для декстопа, когда сбрасываем насадку в поле
    });

    droppable.on('droppable:dropped', (evt) => {
        if (!evt.dropzone.classList.contains('main-dropzone')) {
            if (droppableOrigin !== evt.dropzone.dataset.dropzone) evt.cancel();
        }
    });
}

/**
 * Lazy load
 */
const observer = lozad();
observer.observe();

/**
 * Helpers
 */
const cyrValue = (str) => {
    return /[^а-яА-ЯёЁ -]/gi.test(str);
};
const fancyboxShow = (src, type) => {
    Fancybox.show(
        [
            {
                src: src,
                type: type,
                autoFocus: false,
                trapFocus: false,
                placeFocusBack: false,
            },
        ],
        {
            on: {
                closing: () => {
                    if (isCombinationModal(src)) cleanCombinationModal(src);
                    if (isConstructYourLuxeaPopup(src)) cleanConstructYourLuxeaPopup(src);
                },
            },
        }
    );
};

function isCombinationModal(src) {
    return src === '#combinationModal';
}
function isConstructYourLuxeaPopup(src) {
    return src === '#constructYourLuxeaPopup';
}

function cleanCombinationModal(src) {
    const combinationModal = document.querySelector(src);

    setTimeout(() => {
        const titleInModal = combinationModal.querySelector('.combination-modal__title');
        titleInModal.innerHTML = '';

        const groupAttachments = combinationModal.querySelectorAll(
            '.combination-modal__group-attachments'
        );
        for (const group of groupAttachments) {
            group.style.display = 'none';
        }
    }, 100);
    // setTimeout is used for smooth closing
}
function cleanConstructYourLuxeaPopup(src) {
    const constructYourLuxeaPopup = document.querySelector(src);

    setTimeout(() => {
        for (const logoWithDescr of constructYourLuxeaPopup.querySelectorAll(
            '.construct-your-luxea-modal__logo-with-descr'
        )) {
            logoWithDescr.style.display = 'none';
        }
    }, 100);
    // setTimeout is used for smooth closing
}

/**
 * Disabling autoload on videos
 */
// Fancybox.bind('[data-fancybox]', {
//     Html: {
//         video: {
//             autoplay: false,
//         },
//     },
// });

if (document.querySelector('.move-to')) {
    document.querySelectorAll('.move-to').forEach((el) => {
        el.addEventListener('click', (e) => {
            e.preventDefault();
            const href = e.currentTarget.getAttribute('href');
            const offsetTop = document.querySelector(href).offsetTop;

            window.scroll({
                top: offsetTop,
                behavior: 'smooth',
            });
        });
    });
}

const toast = (title = '', text = '', type = 'info') => {
    let message, messageTitle, messageText;
    message = document.createElement('div');
    messageTitle = document.createElement('p');
    messageTitle.classList.add('title');
    messageTitle.innerHTML = title;
    messageText = document.createElement('p');
    messageText.classList.add('text');
    messageText.innerHTML = text;
    message.append(messageTitle, messageText);

    Toastify({
        className: type,
        close: true,
        destination: false,
        duration: 3000,
        escapeMarkup: false,
        gravity: 'bottom',
        position: 'right',
        stopOnFocus: true,
        text: message.innerHTML,
        onClick: () => {},
    }).showToast();
};

/**
 * Modal
 */
const addModalEvent = (selector) => {
    document.querySelectorAll(selector).forEach((el) => {
        el.addEventListener('click', (evt) => {
            evt.preventDefault();

            const button = evt.currentTarget,
                target = button.hash || button.dataset.href,
                popup = document.querySelector(target),
                title = button.dataset.modalTitle,
                content = button.dataset.modalContent,
                footer = button.dataset.modalFooter,
                submit = button.dataset.modalSubmit,
                yaGoal = button.dataset.modalYagoal,
                email = button.dataset.modalEmail;

            if (title) popup.querySelector('.modal__title span').innerHTML = title;
            if (submit) popup.querySelector("input[type='submit']").value = submit;
            if (yaGoal) popup.querySelector("input[name='ya_goal']").value = yaGoal;
            if (email) popup.querySelector("input[name='email_title']").value = email;
            if (target) fancyboxShow(target, 'inline');
        });
    });
};

if (document.querySelector('.modal-link')) addModalEvent('.modal-link');

/**
 * Form
 */
if (document.querySelector('.modal__form')) {
    document.querySelectorAll('.modal__form').forEach((form) => {
        form.addEventListener('submit', (evt) => {
            evt.preventDefault();
            // let yaGoal = form.querySelector("input[name='ya_goal']").value;
            let phone = form.querySelector("input[name='phone']").value;
            // let   yaMetrikaId = wp_data.ya_metrika_id;
            // let   yaMetrikaGoal = wp_data.ya_metrika_goal_id;

            if (evt.target.closest('#combinationModal')) {
                const combinationModal = evt.target.closest('#combinationModal');
                let popupTitle = '';
                for (const title of combinationModal.querySelectorAll(
                    '.combination-modal__title'
                )) {
                    if (title.style.display !== 'none') {
                        popupTitle = title.innerHTML.trim();
                        break;
                    }
                }
                const formTitleInput = evt.target.querySelector("input[name='email_title']");
                formTitleInput.value = popupTitle;
            }

            if (evt.target.closest('#constructYourLuxeaPopup')) {
                const constructYourLuxeaPopup = evt.target.closest('#constructYourLuxeaPopup');
                const attachmentTypeInput = evt.target.querySelector(
                    "input[name='attachment-type']"
                );

                const logosWithDescrs = constructYourLuxeaPopup.querySelectorAll(
                    '.construct-your-luxea-modal__logo-with-descr'
                );
                let chosenLogosWithDescrs = [];
                for (const logoWithDescr of logosWithDescrs) {
                    if (logoWithDescr.style.display !== 'none')
                        chosenLogosWithDescrs.push(logoWithDescr);
                }

                const attachmentsNames = evt.target.querySelectorAll(
                    '.construct-your-luxea-modal__attachment-name'
                );

                let result = [];

                for (const chosenLogoWithDescr of chosenLogosWithDescrs) {
                    for (const attachmentName of attachmentsNames) {
                        if (chosenLogoWithDescr.dataset.name === attachmentName.dataset.name)
                            result.push(attachmentName.innerHTML.trim());
                    }
                }

                attachmentTypeInput.value = result.join(" ");
            }

            if (phone.length[17] === '_' || phone.length === 0) {
                document.querySelector("input[name='phone']").classList.add('is-error');
            } else {
                // fancyboxShow('#loading', 'inline');
                if (form.querySelector("input[type='submit']"))
                    form.querySelector("input[type='submit']").setAttribute('disabled', '');
                if (form.querySelector("button[type='submit']"))
                    form.querySelector("button[type='submit']").setAttribute('disabled', '');

                let data = new FormData(form);
                mna100('email', data, (r) => {
                    if (r.status === 1) {
                        Fancybox.close();

                        if (form.querySelector("input[type='submit']"))
                            form.querySelector("input[type='submit']").removeAttribute(
                                'disabled',
                                ''
                            );
                        if (form.querySelector("button[type='submit']"))
                            form.querySelector("button[type='submit']").removeAttribute(
                                'disabled',
                                ''
                            );

                        form.querySelectorAll('input').forEach((inp) => {
                            if (
                                inp.type !== 'submit' &&
                                inp.type !== 'checkbox' &&
                                inp.type !== 'radio' &&
                                inp.type !== 'hidden'
                            ) {
                                inp.value = '';
                                inp.parentNode.classList.remove('valid');
                            }
                        });
                        if (form.querySelector('textarea')) {
                            form.querySelector('textarea').value = '';
                            form.querySelector('textarea').parentNode.classList.remove('valid');
                        }
                        if (form.querySelector("input[type='checkbox']")) {
                            form.querySelectorAll("input[type='checkbox']").forEach((el) => {
                                if (el.name != 'agreement') el.checked = false;
                            });
                        }

                        setTimeout(() => {
                            fancyboxShow('#thanks', 'inline');
                        }, 150);
                        setTimeout(() => {
                            Fancybox.close();
                        }, 5000);

                        // if (typeof ym === 'function' && yaMetrikaId) {
                        //     let goal = yaMetrikaGoal ? yaMetrikaGoal : yaGoal;
                        //     ym(yaMetrikaId, 'reachGoal', goal);
                        //     console.log('Цель достигнута: ' + goal);
                        // }
                        // if (typeof gtag === 'function') {
                        //     gtag('event', 'form_lead', {
                        //         event_category: 'lead',
                        //         event_action: 'zayavka',
                        //     });
                        // }
                    } else {
                        toast('Внимание!', 'Ошибка! ' + r.message, 'error');

                        Fancybox.close();
                        form.querySelector("input[type='submit']").removeAttribute('disabled', '');
                    }
                });
            }
        });
    });
}

/**
 * Fields validation
 */

const inputValidate = (selector) => {
    document.querySelectorAll(selector).forEach((el) => {
        if (selector === "input[name='agreement']") {
            el.addEventListener('change', () => {
                const status = el.checked;

                if (status) {
                    el.closest('form')
                        .querySelector("input[type='submit']")
                        .removeAttribute('disabled');
                    el.closest('form').querySelector('.form__submit').classList.remove('disabled');
                } else {
                    el.closest('form')
                        .querySelector("input[type='submit']")
                        .setAttribute('disabled', '');
                    el.closest('form').querySelector('.form__submit').classList.add('disabled');
                }
            });
        }
        if (selector === "input[name='name']") {
            let counter = 0;

            el.addEventListener('keyup', () => {
                if (cyrValue(el.value)) {
                    counter++;

                    if (counter > 5) {
                        toast('Внимание!', 'Имя должно быть на русском языке', 'warning');
                        counter = 0;
                    }
                }

                el.value = el.value.replace(/[^а-яА-ЯёЁ -]/gi, '');
            });
            el.addEventListener('blur', () => {
                if (el.value.length > 0) {
                    el.parentNode.classList.add('valid');
                } else {
                    el.parentNode.classList.remove('valid');
                }
            });
        }
        if (selector === "input[name='phone']") {
            let im = new Inputmask({
                mask: '+7 (999) 999-99-99',
                definitions: {
                    e: {
                        validator: '[0-7,9]',
                    },
                },
            });
            im.mask(el);

            el.addEventListener('blur', () => {
                if (el.value[17] == '_' || el.value[17] == undefined) {
                    toast('Внимание!', 'Некорректный номер', 'warning');
                    el.parentNode.classList.add('error');
                    el.parentNode.classList.remove('valid');
                } else {
                    el.parentNode.classList.add('valid');
                    el.parentNode.classList.remove('error');
                }
            });
        }
        if (selector === "input[name='file_attach[]']") {
            el.addEventListener('change', () => {
                let totalSize = 0;
                Object.keys(e.currentTarget.files).map((objectKey, index) => {
                    totalSize = totalSize + el.files[index].size;
                });
                if (Math.round((totalSize / 1048576) * 100) / 100 > 10) {
                    el.value = '';
                    toast('Внимание!', 'Максимальный объём вложений - 10 мб.', 'warning');
                } else if (el.files.length > 10) {
                    el.value = '';
                    toast('Внимание!', 'Максимальное количество вложений - 10 шт.', 'warning');
                } else {
                    el.closest('form').querySelector('#file-name').innerText =
                        el.files.length +
                        ' шт. (' +
                        Math.round((totalSize / 1048576) * 100) / 100 +
                        ' мб.)';
                    el.closest('form').querySelector('p.filename').classList.add('is-loaded');
                }
            });
            el.closest('form')
                .querySelector('#file-clear')
                .addEventListener('click', (evt) => {
                    el.value = '';
                    evt.currentTarget
                        .closest('form')
                        .querySelector('p.filename')
                        .classList.remove('is-loaded');
                    document.querySelector('#file-name').innerText = '';
                });
        }
        if (
            selector === "input[type='email']" ||
            selector === "input[type='text']" ||
            selector === 'textarea'
        ) {
            el.addEventListener('blur', () => {
                if (el.value.length > 0) {
                    el.parentNode.classList.add('valid');
                } else {
                    el.parentNode.classList.remove('valid');
                }
            });
        }
        if (selector === 'input[required]') {
            el.addEventListener('blur', () => {
                if (el.value.length == 0) {
                    toast('Внимание!', 'Поле обязательно для заполнения', 'warning');
                }
            });
        }
    });
};

if (document.querySelector("input[name='agreement']")) inputValidate("input[name='agreement']");
if (document.querySelector("input[name='name']")) inputValidate("input[name='name']");
if (document.querySelector("input[name='phone']")) inputValidate("input[name='phone']");
if (document.querySelector('input[required]')) inputValidate('input[required]');
if (document.querySelector("input[type='text']")) inputValidate("input[type='text']");
if (document.querySelector("input[type='email']")) inputValidate("input[type='email']");
if (document.querySelector('textarea')) inputValidate('textarea');
if (document.querySelector("input[name='file_attach[]']"))
    inputValidate("input[name='file_attach[]']");

/* Sliders */
if (document.querySelector('.reviews__slider')) {
    const reviewsSlider = new Swiper('.swiper.reviews__slider-container', {
        preloadImages: false,
        spaceBetween: 30,
        loop: true,
        lazy: {
            loadPrevNext: true,
        },
        navigation: {
            prevEl: '.swiper-button-prev.reviews__slider-btn',
            nextEl: '.swiper-button-next.reviews__slider-btn',
        },
        pagination: {
            el: '.swiper-pagination.reviews__slider-pagination',
            clickable: true,
        },
        on: {
            slideChangeTransitionStart: function () {
                for (const slide of this.slides) {
                    if (!slide.classList.contains('swiper-slide-active'))
                        slide
                            .querySelector('.reviews__reviewer-text-wrapper')
                            .classList.remove('show');
                }
            },
        },
    });
}

if (document.querySelector('.swiper.results__slider')) {
    const resultsSlider = new Swiper('.swiper.results__slider', {
        preloadImages: false,
        spaceBetween: 0,
        initialSlide: 1,
        centeredSlides: true,
        lazy: {
            loadPrevNext: true,
        },
        navigation: {
            prevEl: '.swiper-button-prev.results__slider-btn',
            nextEl: '.swiper-button-next.results__slider-btn',
        },
        breakpoints: {
            0: {
                slidesPerView: 1.9,
            },
            620: {
                slidesPerView: 3,
            },
        },
    });
}

if (document.querySelector('a[href="#combinationModal"]')) {
    document.querySelectorAll('a[href="#combinationModal"]').forEach((el) => {
        el.addEventListener('click', (e) => {
            e.preventDefault();

            const combinationModal = document.getElementById('combinationModal');
            const titles = document.querySelectorAll('.popular-combinations__elements-el-title');
            const titleInModal = combinationModal.querySelector('.combination-modal__title');

            const currentCombination = e.target.closest('.popular-combinations__elements-el');
            const currentCombinationOrder = currentCombination.dataset.combinationOrder;
            const currentTitle = currentCombination.querySelector(
                '.popular-combinations__elements-el-title'
            );

            for (const title of titles) {
                if (title.innerText === currentTitle.innerText) {
                    titleInModal.prepend(title.innerText);
                    break;
                }
            }

            const groupAttachments = combinationModal.querySelectorAll(
                '.combination-modal__group-attachments'
            );
            for (const group of groupAttachments) {
                if (group.dataset.combinationOrder === currentCombinationOrder) {
                    group.style.display = '';
                    break;
                }
            }
        });
    });
}

// moving attachments from list to free templates on mobile
if (
    isMobile() &&
    document.querySelector(
        '.construct-your-luxea__attachment-image:not(.construct-your-luxea__attachment-image--copy)'
    )
) {
    document
        .querySelectorAll(
            '.construct-your-luxea__attachment-image:not(.construct-your-luxea__attachment-image--copy)'
        )
        .forEach((el) => {
            el.addEventListener('click', (e) => {
                const templates = document.querySelectorAll(
                    '.construct-your-luxea__attachments-template'
                );

                if (areFreeTemplates(templates)) {
                    const filledTemplates = getFilledTemplates(templates);

                    putAttachmentInTemplate(
                        templates[filledTemplates],
                        e.target,
                        e.target.dataset.name
                    );
                } else {
                    toast(
                        'Выбрано три насадки',
                        'Удалите одну из выбранных, которую хотите заменить',
                        'overflow'
                    );
                }
            });
        });
}

/* При клике на насадку(которая не является копией!) со списка мы получаем список всех шаблонов
Далее вызываем функцию, где перебираем все шаблоны, и проверяем является ли шаблон свободным, если это так, то
вызываем функцию putAttachmentInTemplate */

function getFilledTemplates(templates) {
    let i = 0;
    for (const template of templates) {
        if (!template.classList.contains('free')) i++;
    }
    return i;
}

function areFreeTemplates(templates) {
    for (const template of templates) {
        if (template.classList.contains('free')) return true;
    }
}

function putAttachmentInTemplate(template, target, attachmentName) {
    const attachments = template.querySelectorAll('.construct-your-luxea__hidden-attachment');
    for (const attachment of attachments) {
        if (attachment.dataset.name === attachmentName) {
            attachment.style.display = '';
            break;
        }
    }

    target.classList.add('darken');
    template
        .querySelector('.construct-your-luxea__attachments-template-image')
        .classList.add('shrink');
    template.classList.remove('free');
}

/* ------------- */

// removing attachments from templates on mobile
if (isMobile() && document.querySelector('.construct-your-luxea__attachments-template')) {
    document.querySelectorAll('.construct-your-luxea__attachments-template').forEach((el) => {
        el.addEventListener('click', (e) => {
            const template = e.target.closest('.construct-your-luxea__attachments-template');

            for (const hiddenAttachment of template.querySelectorAll(
                '.construct-your-luxea__hidden-attachment'
            )) {
                if (hiddenAttachment.style.display !== 'none') {
                    e.target.style.display = 'none';
                    console.log(e.target);
                    const hiddenAttachemntName = e.target.dataset.name;

                    const attachments = document.querySelectorAll(
                        '.construct-your-luxea__attachment-image:not(.construct-your-luxea__attachment-image--copy)'
                    );

                    for (const attachment of attachments) {
                        if (attachment.dataset.name === hiddenAttachemntName) {
                            attachment.classList.remove('darken');
                        }
                    }

                    template
                        .querySelector('.construct-your-luxea__attachments-template-image')
                        .classList.remove('shrink');
                    template.classList.add('free');
                }
            }
        });
    });
}

if (document.querySelector('.attachments__elements-el-btn')) {
    document.querySelectorAll('.attachments__elements-el-btn').forEach((el) => {
        el.addEventListener('click', (e) => {
            const attachmentName = e.target.dataset.name;
            const filledTemplates = document.querySelectorAll(
                '.construct-your-luxea__attachments-template:not(.free)'
            );
            const templates = document.querySelectorAll(
                '.construct-your-luxea__attachments-template'
            );

            if (filledTemplates.length === 0) {
                setTimeout(() => {
                    putAttachmentAfterBtnClick(attachmentName);
                }, 1000);
            } else if (templates.length === filledTemplates.length) {
                toast(
                    'Выбрано три насадки',
                    'Удалите одну из выбранных, которую хотите заменить',
                    'overflow'
                );
            } else {
                outer: for (const template of filledTemplates) {
                    if (isMobile()) {
                        for (const attachment of template.querySelectorAll(
                            '.construct-your-luxea__hidden-attachment'
                        )) {
                            if (attachment.style.display !== 'none') {
                                if (attachment.dataset.name === attachmentName) {
                                    toast('Выбранная насадка уже присутствует', '', 'overflow');
                                    break outer;
                                } else {
                                    setTimeout(() => {
                                        putAttachmentAfterBtnClick(attachmentName);
                                    }, 1000);
                                }
                            }
                        }
                    } else {
                        for (const i of filledTemplates) {
                            if (
                                i.querySelector('.construct-your-luxea__attachment-image').dataset
                                    .name === attachmentName
                            ) {
                                toast('Выбранная насадка уже присутствует', '', 'overflow');
                                break outer;
                            }

                            /* Верхний цикл проходится чтоб взять template, а этот используется, для прохождения по всем templates каждый раз, когда доходим до нового template(из верхнего цкила) */
                        }

                        setTimeout(() => {
                            putAttachmentAfterBtnClick(attachmentName);
                        }, 1000);
                    }
                }
            }
        });
    });
}

function putAttachmentAfterBtnClick(attachmentName) {
    const templates = document.querySelectorAll('.construct-your-luxea__attachments-template');

    if (!isMobile()) {
        for (const template of templates) {
            if (template.classList.contains('free')) {
                const attachments = document.querySelectorAll('.construct-your-luxea__attachment');

                for (const attachment of attachments) {
                    if (
                        attachment.querySelector(
                            '.construct-your-luxea__attachment-image:not(.construct-your-luxea__attachment-image--copy)'
                        ) &&
                        attachment.querySelector(
                            '.construct-your-luxea__attachment-image:not(.construct-your-luxea__attachment-image--copy)'
                        ).dataset.name === attachmentName

                        // такое условие, на случай, когда проход упирается в пустую насадку. Чтобы не было ошибки
                    ) {
                        const attachmentThatMustBePlaced = attachment.querySelector(
                            '.construct-your-luxea__attachment-image:not(.construct-your-luxea__attachment-image--copy)'
                        );

                        template.append(attachmentThatMustBePlaced);
                        break;
                    }
                }

                template.classList.add('draggable-dropzone--occupied');
                template.classList.remove('free');
                break;
            }
        }
    } else {
        for (const template of templates) {
            if (template.classList.contains('free')) {
                const attachments = template.querySelectorAll(
                    '.construct-your-luxea__hidden-attachment'
                );

                for (const attachment of attachments) {
                    if (attachment.dataset.name === attachmentName) {
                        attachment.style.display = '';
                        break;
                    }
                }
                const notHiddenAttachments = document.querySelectorAll(
                    '.construct-your-luxea__attachment-image:not(.construct-your-luxea__attachment-image--copy)'
                );

                for (const notHiddenAttachment of notHiddenAttachments) {
                    if (notHiddenAttachment.dataset.name === attachmentName) {
                        notHiddenAttachment.classList.add('darken');
                    }
                }
                template
                    .querySelector('.construct-your-luxea__attachments-template-image')
                    .classList.add('shrink');

                template.classList.remove('free');
                break;
            }
        }
    }
}

if (document.querySelector('[href="#constructYourLuxeaPopup"]')) {
    document.querySelectorAll('[href="#constructYourLuxeaPopup"]').forEach((el) => {
        el.addEventListener('click', (e) => {
            e.preventDefault();

            const constructYourLuxeaPopup = document.querySelector('#constructYourLuxeaPopup');

            const logosWithDescrs = constructYourLuxeaPopup.querySelectorAll(
                '.construct-your-luxea-modal__logo-with-descr'
            );

            const templates = document.querySelectorAll(
                '.construct-your-luxea__attachments-template'
            );

            if (isMobile()) {
                for (const template of templates) {
                    for (const hiddenAttachment of template.querySelectorAll(
                        '.construct-your-luxea__hidden-attachment'
                    )) {
                        if (hiddenAttachment.style.display !== 'none') {
                            for (const logoWithDescr of logosWithDescrs) {
                                if (hiddenAttachment.dataset.name === logoWithDescr.dataset.name) {
                                    logoWithDescr.style.display = '';
                                }
                            }
                        }
                    }
                }
            } else {
                for (const template of templates) {
                    if (template.querySelector('.construct-your-luxea__attachment-image')) {
                        for (const logoWithDescr of logosWithDescrs) {
                            if (
                                template.querySelector('.construct-your-luxea__attachment-image')
                                    .dataset.name === logoWithDescr.dataset.name
                            ) {
                                logoWithDescr.style.display = '';
                            }
                        }
                    }
                }
            }
        });
    });
}

if (document.querySelector('.reviews__review-show-more-btn')) {
    document.querySelectorAll('.reviews__review-show-more-btn').forEach((el) => {
        el.addEventListener('click', (e) => {
            e.currentTarget.closest('.reviews__reviewer-text-wrapper').classList.toggle('show');
        });
    });
}

{
    document.body.style.setProperty('--calc-height', 'auto');
    const calcTargets = document.getElementsByClassName('reviews__review-calc-child-size'),
        resize = () => {
            for (let target of calcTargets) {
                let size = target.firstElementChild.clientHeight + 'px';
                if (target.style.getPropertyValue('--calc-height') !== size) {
                    target.style.setProperty('--calc-height', size);
                }
            }
        };

    window.addEventListener('resize', resize);
    window.addEventListener('load', resize);
}
